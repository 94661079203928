import React from 'react';
import { Link, navigate } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import { Typography, Button, useTheme, useMediaQuery } from '@material-ui/core';
import Layout from "../components/Layout"

const useStyles = makeStyles(theme => ({
  root: {
    padding: 24,
    paddingTop: '10vh',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center'
  },
  typo:{
    color: '#263238',
    fontSize: '35px',
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: '40px',
    letterSpacing: '-0.24px'
  },
  typoSubtitle:{
      color: '#546e7a',
      fontSize: '14px',
      fontFamily: "Roboto",
      fontWeight: 400,
      lineHeight: '21px',
      letterSpacing: '-0.05px',
  },
  imageContainer: {
    marginTop: '48px',
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto'
  },
  buttonContainer: {
    marginTop: 48,
    display: 'flex',
    justifyContent: 'center'
  }
}));

const Error404 = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));


  const handleNavigate = () => {
      navigate('/')
  }

  return (
    <div
      className={classes.root}
      title="Error 404"
    >
      <Typography
        align="center"
        className={classes.typo}
        variant={mobileDevice ? 'h4' : 'h1'}
      >
        404: Pagina no existe o esta siendo creada
      </Typography>
      <Typography
        align="center"
        className={classes.typoSubtitle}
        variant="subtitle2"
      >
        Si no estas aqui por error, puede que la pagina este siendo creada en estos
        momentos, tomate un cafe y vuelve en 5 minutos :)
      </Typography>
      <div className={classes.imageContainer}>
        <img
          alt="Under development"
          className={classes.image}
          src={require("./../images/undraw_page_not_found_su7k.svg")}
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          onClick={handleNavigate}
          variant="outlined"
        >
          Volver al Inicio
        </Button>
      </div>
    </div>
  );
};

export default Error404;
